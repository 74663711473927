/**
 * Axios based error handler (taken from app/javascript/customer/helpers/error_handler.js)
 **/

const fallback_error_message_if_missing = "Sorry, something went wrong. Please try again or contact us at support@songfinch.com.";

export default (e, defaultMessage) => {
    let error;
    if (e?.response?.data?.error) {
        return e.response.data;
    } else if (e?.message === "Network Error") {
        error = "Network Error. Please check your internet connection or contact us at support@songfinch.com.";
    } else {
        //Legacy behavior is to store the error on the "error" property of the exception
        //Updated behavior should embedded the error message on the "message" property
        error = e?.message || e?.error || defaultMessage || fallback_error_message_if_missing;
    }
    return {error};
};
